.board-wrapper {
    width: 100px;
    height: 100px;
    touch-action: none;
    // &.normal .chess-board {
    //     background-image: url('../svg/board/brownBoard.svg');
    // }
    // &.green .chess-board {
    //     background-image: url('../svg/board/greenBoard.svg');
    // }
    // &.dark-green .chess-board {
    //     background-image: url('../svg/board/AbrenicaBoard.svg');
    // }
    // &.test .chess-board {
    //     background-image: url('../svg/board/testBoard.svg');
    // }
}

#main-board {
    width: inherit;
    height: inherit;
    position: absolute;
    pointer-events: none;
    touch-action: none;
}

#best-move,
#legal-moves-layer {
    position: absolute;
    width: inherit;
    height: inherit;
    pointer-events: none;
}

#custom-svg {
    @extend #best-move;
    z-index: 5;
    transition: all 300ms;
}

#board-svg {
    @extend #best-move;
}

#pieces-layer {
    @extend #legal-moves-layer;
    z-index: 2;
    pointer-events: none;
}

.valid-move {
    shape-rendering: auto;
    background: radial-gradient(rgba(20, 85, 30, 0.5) 19%, rgba(0, 0, 0, 0) 20%);
    width: 12.5%;
    height: 12.5%;
    margin: auto;
    position: absolute;
    background-size: cover;
    will-change: transform;
    z-index: 2;
    pointer-events: all;

    &.capture {
        background: radial-gradient(transparent 0%, transparent 79%, rgba(20, 85, 0, 0.5) 80%);
    }

    &:hover {
        background: rgba(20, 85, 30, 0.3);
    }
}

.piece {
    width: 12.5%;
    height: 12.5%;
    margin: auto;
    position: absolute;
    z-index: 6;
    opacity: 0%;
    transform: scale(2);
    opacity: 100%;
    top: 0;
    left: 0;
    transition: all 0ms;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    will-change: transform;
    pointer-events: none;
    cursor: pointer;

    &.ghost {
        background-color: rgba(20, 85, 30, 0.5);
        z-index: 2;
        opacity: 60%;
    }

    &.ani {
        transition: all 300ms !important;
        z-index: 4;
    }

    &.mobile-drag {
        width: 20%;
        height: 20%;
        z-index: 99;
    }
}

#spectators-in-game-display {
    li {
        font-size: larger;
        font-weight: bolder;

        margin: 30px;

        .rating {
            color: grey;
            margin-left: 10px;
        }

        list-style: none;
    }
}

.square {
    pointer-events: none;
    width: 12.5%;
    height: 12.5%;
    margin: auto;
    position: absolute;
    z-index: 1;
    background-size: cover;
    will-change: transform;

    &.prevMove {
        &.start {
            &.self {
                background-color: rgba(131, 131, 225, 0.5);
            }

            &.other {
                background-color: rgba(225, 131, 131, 0.5);
            }
        }

        &.end {
            &.self {
                background-color: rgba(46, 46, 228, 0.5);
            }

            &.other {
                background-color: rgba(228, 46, 46, 0.5);
            }
        }
    }

    &.check {
        background: radial-gradient(ellipse at center, red 0%, #e70000 25%, rgba(169, 0, 0, 0) 89%, rgba(158, 0, 0, 0) 100%);
    }

    &.premove {
        background-color: rgba(255, 255, 0, 0.5);
    }
}

#promotion-choice {
    position: absolute;
    background: rgba(22, 21, 18, 0.7);
    z-index: 205;
    position: absolute;
    width: inherit;
    height: inherit;

    .square {
        cursor: pointer;
        border-radius: 50%;
        background-color: #b0b0b0;
        box-shadow: inset 0 0 25px 3px #808080;
        transition: all 150ms;

        &:hover {
            box-shadow: inset 0 0 48px 8px #d64f00;
            border-radius: 0%;

            .piece {
                transform: none
            }
        }
    }

    .piece {
        pointer-events: auto;
        opacity: 1;
        width: 100%;
        height: 100%;
        transition: all 150ms;
        transform: scale(0.8)
    }
}

.coords {
    pointer-events: none;
    position: absolute;
    display: flex;
    pointer-events: none;
    opacity: 0.8;
    font-family: Arial;
    font-size: 2vmin;

    p {
        margin: 0;
        flex: 1 1 auto;
    }

    &.ranks {
        margin: 0;
        text-align: right;
        right: 0.5%;
        top: 0;
        flex-flow: column-reverse;
        height: 100%;
        width: 12px;

        &.black {
            flex-flow: column;

            p {
                &:nth-child(2n) {
                    color: rgba(72, 72, 72, 0.8);
                }

                &:nth-child(2n+1) {
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }

        &.left {
            left: -15px;
            align-items: flex-end;
        }

        p {
            &:nth-child(2n) {
                color: rgba(255, 255, 255, 0.8);
            }

            &:nth-child(2n+1) {
                color: rgba(72, 72, 72, 0.8);
            }
        }
    }

    &.files {
        bottom: 0;
        left: 0.5%;
        flex-flow: row;
        width: 100%;
        text-transform: lowercase;
        text-align: center;

        &.black {
            flex-flow: row-reverse;

            p {
                &:nth-child(2n) {
                    color: rgba(255, 255, 255, 0.8);
                }

                &:nth-child(2n+1) {
                    color: rgba(72, 72, 72, 0.8);
                }
            }
        }

        p {
            text-align: left;

            &:nth-child(2n) {
                color: rgba(72, 72, 72, 0.8);
            }

            &:nth-child(2n+1) {
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}