table {
    margin: 10px;
}

.horizontal-game-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    font-weight: normal;
    .game-controls-info {
        flex-basis: 100%;
    }
    hr {
        margin-top: 10px;
    }
}

.game-controls-info {
    display: flex;
    align-items: center;
    #game-controls {
        margin-left: 20px;
        button {
            padding: 7px;
            margin: 10px;
        }
    }
}

.full-screen-menu {
    min-width: 100%;
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    br {
        margin-top: 10px;
    }
}

#stockfish-slider {
    width: 30%;
}

.col-down {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.moves {
        height: 100%;
    }
}

div.piece-style-btn {
    display: inline-block;
    &.current div.display-piece {
        filter: brightness(0.5);
        cursor: not-allowed;
        &:hover {
            background-color: var(--grey);
            transform: scale(1);
        }
    }
    img.display-piece {
        width: 70px;
        height: 70px;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
        background-color: var(--grey);
        margin: 3px;
        border-radius: 20px;
        transition: all 300ms;
        cursor: pointer;
        &:hover {
            background-color: grey;
            transform: scale(1.5);
        }
    }
    z-index: 1;
    &:hover {
        z-index: 5;
    }
}

.white-square {
    fill: white;
}

.black-square {
    fill: grey;
}

#board-and-info {
    p {
        font-weight: normal;
        margin-top: 5px;
        width: calc(100% - 10px);
        &.opening {
            margin-left: 10px;
            .eco {
                margin-right: 7px;
                color: grey;
            }
            overflow: hidden;
        }
    }
    .mobile-engline-info {
        margin-left: 10px;
        padding-right: 20px;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;

        .eval-engine-info {
            margin-right: 10vw;
            width: fit-content;
            display: inline-block;
            background-color: rgb(100, 0, 100);
            padding: 5px 7px;
            border-radius: 3px;
        }
    }
    div.inline-info {
        margin-left: 5px;
        display: flex;
        p.button-type {
            flex-basis: 20%;
        }
        align-items: center;
    }
    form {
        width: 100%;
        label {
            margin: 0;
            width: 100%;
            input {
                margin: 0;
                margin-left: 5px;
                padding-left: 0;
                width: 100%;
            }
        }
    }
}

.game-controls-info {
    margin: 18px;
    font-size: large;
}

@font-face {
    font-family: ChessFont;
    src: url(../resources/lichess.chess.woff2);
}

#previous-moves-wrapper {
    max-height: 87vh;
    margin-left: 10px;
    // margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-basis: calc(100% - 20px);
    .engine-info {
        position: -webkit-sticky;
        width: auto;
        position: sticky;
        top: 0;
        h3 {
            font-size: x-large;
            margin: 0;
        }
        h4 {
            font-size: medium;
        }
        p {
            margin: 2px;
            margin-left: 0;
            margin-top: 7px;
            font-size: small;
        }
        color: white;
        background: rgb(100, 0, 100);
        padding: 13px;
        border-radius: 20px;
        margin: 10px;
        #eval-pvs td {
            &.eval {
                font-weight: bolder;
                padding-right: 20px;
                font-size: medium;
            }
            &.pv {
                font-size: medium;
                overflow-x: hidden;
            }
        }
    }
    &.mobile {
        .scollable {
            max-height: 50vh;
        }
    }
    .scollable {
        height: 100%;
        overflow-y: auto;
        width: 100%;
    }
    .game-over {
        background-color: green;
        color: white;
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
        font-weight: bolder;
    }
    #previous-moves {
        font-family: ChessFont, 'Noto Sans JP', sans-serif;
        font-size: large;
        width: 90%;
        thead {
            font-family: var(--font-1);
            tr th {
                color: white !important;
                text-align: center;
            }
        }
        tbody {
            tr.first-row {
                font-family: var(--font-1);
            }
            th {
                font-family: var(--font-1);
            }
        }
        margin: 5px;
        th,
        tr,
        td {
            padding: 0;
            border-style: solid;
            border-color: rgba(255, 255, 255, 0);
        }
        th {
            color: grey;
        }
        tr.game-over td {
            border-radius: 5px;
        }
        p {
            width: 100%;
            height: 100%;
            margin: 0;
            text-align: center;
        }
        .current-move {
            border-radius: 5px;
            border-color: red;
            border-style: solid;
        }
    }
    .game-controls {
        display: flex;
        flex-direction: row;
        // height: 4em;
        p {
            -webkit-touch-callout: none;
            /* iOS Safari */
            -webkit-user-select: none;
            /* Safari */
            -khtml-user-select: none;
            /* Konqueror HTML */
            -moz-user-select: none;
            /* Old versions of Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            user-select: none;
            /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
            span.material-icons-sharp {
                font-size: xx-large !important;
                pointer-events: none;
            }
            margin-top: 10px;
            background-color: var(--grey);
            border-radius: 5px;
            width: 100%;
            text-align: center;
            margin-left: 5px;
            margin-right: 5px;
            cursor: pointer;
            transition: all 300ms;
            &:hover {
                background-color: grey;
            }
            &.disabled {
                background-color: rgb(39, 39, 39);
                cursor: not-allowed;
            }
            span {
                padding-top: 4px;
                &.done {
                    animation-duration: 0.75s;
                    animation-name: copySuccess;
                    transform: scale(1);
                }
                // font-size: small;
            }
        }
    }
}

@keyframes copySuccess {
    0% {
        colour: white;
    }

    10% {
        transform: scale(0);
        colour: white;
    }

    50% {
        transform: scale(2);
        color: green;
    }

    90% {
        color: green;
        // transform: ;
    }

    100% {
        color: white;
        transform: scale(1);
    }
}

.player-info {
    height: auto;
    font-size: medium;
    padding: 0;
    h4 {
        margin: 6px;
        .rating {
            text-align: right;
            font-size: small;
            color: grey;
        }
        &.timer {
            float: right;
            // margin-left: 100%;
            color: black;
            text-align: right;
            position: absolute;
            right: 0;
        }
    }
    .material {
        &.pieces {
            img {
                display: inline;
                vertical-align: middle;
                margin: auto;
                height: 1.5em;
                margin-bottom: 5px;
                margin-right: 3px;
            }
        }
        &.points {
            width: fit-content !important;
        }
    }
    div,
    p,
    h4 {
        display: inline-block;
    }
    &.white {
        color: black;
        background-color: white;
    }
    &.black {
        color: white;
        background: black;
        .timer {
            color: white;
        }
    }
    &.isTurn {
        border-style: solid;
        border-width: 3px;
        border-color: green;
        animation: pulseGreen 3000ms linear infinite;
    }
}

@keyframes pulseGreen {
    0% {}
    5% {
        background-color: green;
    }
    40% {}
    100% {}
}