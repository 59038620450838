.p.l {
    background-image: url('./normal/pawnWhite.svg')
}

.n.l {
    background-image: url('./normal/knightWhite.svg')
}

.b.l {
    background-image: url('./normal/bishopWhite.svg')
}

.r.l {
    background-image: url('./normal/rookWhite.svg')
}

.q.l {
    background-image: url('./normal/queenWhite.svg')
}

.k.l {
    background-image: url('./normal/kingWhite.svg')
}

.p.d {
    background-image: url('./normal/pawnBlack.svg')
}

.n.d {
    background-image: url('./normal/knightBlack.svg')
}

.b.d {
    background-image: url('./normal/bishopBlack.svg')
}

.r.d {
    background-image: url('./normal/rookBlack.svg')
}

.q.d {
    background-image: url('./normal/queenBlack.svg')
}

.k.d {
    background-image: url('./normal/kingBlack.svg')
}

.ewan {
    .p.l {
        background-image: url('./ewan/white pawn.svg')
    }
    .n.l {
        background-image: url('./ewan/white knight.svg')
    }
    .b.l {
        background-image: url('./ewan/white bishop.svg')
    }
    .r.l {
        background-image: url('./ewan/white rook.svg')
    }
    .q.l {
        background-image: url('./ewan/white queen.svg')
    }
    .k.l {
        background-image: url('./ewan/white king.svg')
    }
    .p.d {
        background-image: url('./ewan/black pawn.svg')
    }
    .n.d {
        background-image: url('./ewan/black knight.svg')
    }
    .b.d {
        background-image: url('./ewan/black bishop.svg')
    }
    .r.d {
        background-image: url('./ewan/black rook.svg')
    }
    .q.d {
        background-image: url('./ewan/black queen.svg')
    }
    .k.d {
        background-image: url('./ewan/black king.svg')
    }
}

.medieval {
    .p.l {
        background-image: url('./medieval/White Pawn Default.svg')
    }
    .n.l {
        background-image: url('./medieval/White Knight Default.svg')
    }
    .b.l {
        background-image: url('./medieval/White Bishop Default.svg')
    }
    .r.l {
        background-image: url('./medieval/White Rook Default.svg')
    }
    .q.l {
        background-image: url('./medieval/White Queen Default.svg')
    }
    .k.l {
        background-image: url('./medieval/White King Default.svg')
    }
    .p.d {
        background-image: url('./medieval/Black Pawn Default.svg')
    }
    .n.d {
        background-image: url('./medieval/Black Knight Default.svg')
    }
    .b.d {
        background-image: url('./medieval/Black Bishop Default.svg')
    }
    .r.d {
        background-image: url('./medieval/Black Rook Default.svg')
    }
    .q.d {
        background-image: url('./medieval/Black Queen Default.svg')
    }
    .k.d {
        background-image: url('./medieval/Black King Default.svg')
    }
}

.sus {
    .p.l {
        background-image: url('./sus/white pawn.png')
    }
    .n.l {
        background-image: url('./sus/white knight.png')
    }
    .b.l {
        background-image: url('./sus/white bishop.png')
    }
    .r.l {
        background-image: url('./sus/white rook.png')
    }
    .q.l {
        background-image: url('./sus/white queen.png')
    }
    .k.l {
        background-image: url('./sus/white king.png')
    }
    .p.d {
        background-image: url('./sus/black pawn.png')
    }
    .n.d {
        background-image: url('./sus/black knight.png')
    }
    .b.d {
        background-image: url('./sus/black bishop.png')
    }
    .r.d {
        background-image: url('./sus/black rook.png')
    }
    .q.d {
        background-image: url('./sus/black queen.png')
    }
    .k.d {
        background-image: url('./sus/black king.png')
    }
}