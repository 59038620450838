#account-form {
    background-image: url("../resources/favicon-login-bg.png");
    background-size: min(100vh - 40px - 60px, 100vw - 60px);
    background-repeat: no-repeat;
    background-position: calc((100vw - min(100vh - 40px - 60px, 100vw - 60px)) / 2), calc(((100vh - 40px) - min(100vh - 40px - 60px, 100vw - 60px)) / 2);
    // position: absolute;
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 70px;
    }
    h2.account-error {
        font-size: large;
        background-color: rgb(253, 132, 132, 0.2);
        border-style: solid;
        border-color: red;
        padding: 10px;
        border-radius: 5px;
    }
    p {
        margin-top: 20px;
        margin-bottom: 5px;
    }
    a {
        color: white;
        text-decoration: none;
        font-weight: 500;
    }
    overflow: hidden;
}

.queueing {
    min-width: 100vw;
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
