div.loading {
    text-align: center;
    img {
        -moz-animation-name: roll;
        -moz-animation-duration: 2.5s;
        -moz-animation-iteration-count: infinite;
        -webkit-animation-name: roll;
        -webkit-animation-duration: 2.5s;
        -webkit-animation-iteration-count: infinite;
        animation-name: roll;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        &#oulingchess {
            width: min(75vw, 70vh);
        }
    }
    overflow: hidden;
}

@-webkit-keyframes roll {
    from {
        -webkit-transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(360deg)
    }
}

@-moz-keyframes roll {
    from {
        -moz-transform: rotate(0deg)
    }
    to {
        -moz-transform: rotate(360deg)
    }
}

@keyframes roll {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}